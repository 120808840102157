<nav
  #menu
  class="menu-lateral-wrapper">
  <button
    class="menu-lateral-wrapper-resize-button"
    (click)="mudarVisualizacaoMenu.emit()">
    <i class="bx bx-chevron-left"></i>
  </button>
  <div class="menu-lateral">
    <div class="menu-particao-container">
      <div
        *ngFor="let particao of menuConfig; let partIndex = index"
        class="menu-particao">
        <h6 class="menu-particao-titulo">{{ particao?.particao?.nome }}</h6>

        <ul class="menu-particao-items">
          <ng-template
            ngFor
            let-item
            [ngForOf]="particao?.items"
            let-itemIndex="index">
            <li
              *ngIf="item?.subItems"
              class="menu-particao-item"
              [ngClass]="{ 'open-item': item?.isAberto }"
              routerLinkActive="active-link"
              tabindex="0"
              (keydown.enter)="lidarComItemClick(partIndex, itemIndex)"
              (focus)="
                menu.classList.contains('menu-lateral-colapsado') &&
                  lidarComItemClick(partIndex, itemIndex, true, $event)
              ">
              <div
                class="menu-particao-item-header"
                (click)="
                  !menu.classList.contains('menu-lateral-colapsado') &&
                    lidarComItemClick(partIndex, itemIndex, undefined, $event)
                "
                (mouseenter)="
                  menu.classList.contains('menu-lateral-colapsado') &&
                    lidarComItemClick(partIndex, itemIndex, true, $event)
                ">
                <i [class]="item.icone"></i>
                <span>{{ item?.nome }}</span>
                <i
                  class="bx bx-chevron-down arrow-icon"
                  [ngClass]="{ 'opened-item-icon': item?.isAberto }"></i>
              </div>
              <ul
                *ngIf="item?.subItems?.length"
                class="menu-particao-item-sub-items"
                (click)="$event.stopPropagation()">
                <li class="menu-particao-item-sub-item sub-items-title">{{ item?.nome }}</li>
                <li
                  *ngFor="let subItem of item?.subItems"
                  [routerLink]="[
                    item?.path
                      ? item?.path + '/' + (subItem?.path ?? formatarTexto(subItem.nome))
                      : subItem?.path ?? formatarTexto(subItem.nome)
                  ]"
                  routerLinkActive="active-link"
                  class="menu-particao-item-sub-item"
                  [tabindex]="!item?.isAberto && -1"
                  (keydown.enter)="
                    navegarParaEndpoint(
                      item?.path
                        ? item?.path + '/' + (subItem?.path ?? formatarTexto(subItem.nome))
                        : subItem?.path ?? formatarTexto(subItem.nome)
                    )
                  ">
                  {{ subItem?.nome }}
                </li>
              </ul>
            </li>

            <li
              *ngIf="!item?.subItems"
              [title]="item?.nome"
              class="menu-particao-item"
              (click)="lidarComItemClick(partIndex, itemIndex)"
              routerLinkActive="active-link"
              [routerLink]="[item?.path ?? formatarTexto(item.nome)]"
              (keydown.enter)="navegarParaEndpoint(item?.path ?? '')">
              <div class="menu-particao-item-header">
                <i [class]="item.icone"></i>
                <span>{{ item?.nome }}</span>
              </div>
            </li>
          </ng-template>
        </ul>
      </div>
    </div>
  </div>
</nav>
