<div class="layout-container" #layout>
  <header class="header" (click)="lidarComClickForaDoMenu()">
    <div class="header-left">
      <div class="menu-icon-container" (click)="mudarVisualizacaoMenu($event)">
        <i class="bx bx-menu"></i>
      </div>
      <div class="header-left-logo">
        <img
          src="assets/imagens/logos/destaques/logo_destaque_1.png"
          priority
          alt="Logo G&T Educação"
          draggable="false"
        />
      </div>
      <div class="header-left-site-name">
        <span>Destaque Sistemas</span>
        <span>Módulo de Gestão</span>
      </div>
    </div>

    <div class="config-container">
      <div class="btn-group" dropdown>
        <div class="config-pfp">
          <ng-container *ngTemplateOutlet="pfp"></ng-container>
          <button class="config-pfp-dropdown-button" dropdownToggle>
            <i class="bx bx-chevron-down"></i>
          </button>
        </div>

        <div
          id="dropdown-split"
          *dropdownMenu
          class="dropdown-menu dropdown-menu-end"
          role="menu"
          aria-labelledby="button-split"
          container="body"
          (click)="$event.stopPropagation()"
        >
          <div class="dropdown-header">
            <ng-container *ngTemplateOutlet="pfp"></ng-container>
            <div class="dropdown-header-text">
              <h6>{{ usuario?.nome ?? '-' }}</h6>
              <p>{{ usuario?.email }}</p>
            </div>
          </div>
          <ul>
            <li role="menuitem">
              <button
                (click)="deslogar()"
                [disabled]="disabilitarBtn"
                class="dropdown-item logout"
              >
                <i class="bx bx-log-out bx-rotate-180"></i>
                <span>Terminar Sessão</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>

  <app-side-menu
    (mudarVisualizacaoMenu)="mudarVisualizacaoMenu()"
  ></app-side-menu>

  <div class="content" (click)="lidarComClickForaDoMenu()">
    <div class="content-page">
      <!-- <app-breadcrumb></app-breadcrumb> -->
      <router-outlet></router-outlet>
    </div>

    <footer class="content-footer">
      <div class="content-footer-right">
        <span>• Politica de Privacidade</span>
      </div>
    </footer>
  </div>
</div>

<ng-template #pfp>
  <div class="img-frame">
    <img
      *ngIf="!usuario?.iniciais"
      [src]="usuario?.foto_link ?? 'assets/imagens/outros/user-one.jpg'"
      priority
      alt="Foto do Usuário"
      draggable="false"
    />
    <div *ngIf="usuario?.iniciais" class="img-frame-iniciais">
      <span>{{ usuario?.iniciais }}</span>
    </div>
    <label for="foto_header" class="img-frame-upload">
      <span><i class="bx bx-upload"></i></span>
    </label>
    <input
      class="file-input"
      id="foto_header"
      type="file"
      (change)="mostrarModal($event)"
    />
  </div>
</ng-template>

<ng-template #editorDeImagem>
  <app-editor-de-imagem
    (dispensar)="
      limparInput(arquivoParametros.inputConfig.nome);
      imageChangedEvent = null;
      modalRefEditorImg.hide()
    "
    (atualizarComponente)="
      modalRefEditorImg.hide();
      alterarFoto($event);
      limparInput(arquivoParametros.inputConfig.nome)
    "
    [imageChangedEvent]="imageChangedEvent"
  ></app-editor-de-imagem>
</ng-template>