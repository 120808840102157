import { Routes } from '@angular/router';
import { AutorizacaoGuard } from 'src/app/shared/guards/autorizacao.guard';
import { ContainerComponent } from 'src/app/shared/componentes/layout/container/container.component';
import { LoginContainerComponent } from './auth/login-container/login-container.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  {
    path: 'sistema/:cliente',
    data: {
      breadcrumb: null
    },
    canActivate: [AutorizacaoGuard],
    component: ContainerComponent,
    loadChildren: () => import('./pages/pages.routes')
  },
  {
    path: 'auth',
    component: LoginContainerComponent,
    loadChildren: () => import('./auth/auth.routes')
  }
];
