import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ArmazenamentoDeTokenService } from 'src/app/shared/services/armazenamento/armazenamento-de-token.service';
import { SideMenuComponent } from 'src/app/shared/componentes/layout/side-menu/side-menu.component';
import { ArmazenamentoDeUsuarioService } from 'src/app/shared/services/armazenamento/armazenamento-de-usuario.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BreadcrumbComponent } from '../../breadcrumb/breadcrumb.component';
import { GeralService, LoginService } from 'src/app/shared/services/http';
import { HttpStatusCode } from '@angular/common/http';
import { Endpoints } from 'src/app/shared/utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditorDeImagemComponent } from '../../editor-de-imagem/editor-de-imagem.component';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage,
    BsDropdownModule,
    SideMenuComponent,
    NgSelectModule,
    ReactiveFormsModule,
    BreadcrumbComponent,
    EditorDeImagemComponent
  ],
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('layout') layout: ElementRef = {} as ElementRef;
  @ViewChild(SideMenuComponent) sidebar: SideMenuComponent =
    {} as SideMenuComponent;
  @ViewChild('editorDeImagem') editorDeImagem!: TemplateRef<any>;

  public APIEndpoints: any = Endpoints;
  public endpointPrincipal: string = this.APIEndpoints.usuarios;
  public modalRefEditorImg!: BsModalRef;
  public arquivoParametros: any;
  public imageChangedEvent!: Event | null;
  public usuario: any;
  public larguraDaPagina: any;
  public larguraMenuFixo: number = 800;
  public form!: FormGroup;
  public disabilitarBtn: boolean = false;
  private subs: Subscription = new Subscription();

  constructor(
    private armazenamentoDeTokenService: ArmazenamentoDeTokenService,
    public armazenamentoDeUsuarioService: ArmazenamentoDeUsuarioService,
    public loginService: LoginService,
    private geralService: GeralService,
    private router: Router,
    public modalService: BsModalService
  ) {}

  ngAfterViewInit(): void {
    this.larguraDaPagina = window.innerWidth;
    if (this.larguraDaPagina >= this.larguraMenuFixo) {
      this.abrirMenu();
    } else {
      this.colapsarMenu();
    }
  }

  ngOnInit(): void {
    const dados = this.armazenamentoDeUsuarioService.buscarUsuario();

    if (dados?.foto_link) {
      this.usuario = dados;
    } else {
      const nomes = dados?.nome?.split(' ') ?? [];
      const InicialPrimeiroNome = nomes[0] ? nomes[0][0] : '';
      const InicialSegundoNome = nomes[1] ? nomes[1][0] : '';
  
      this.usuario = {
        ...dados,
        iniciais: InicialPrimeiroNome + InicialSegundoNome,
      };
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.lidarComRedimensionamento();
  }

  public lidarComRedimensionamento() {
    const larguraAtual = window.innerWidth;

    if (
      larguraAtual >= this.larguraMenuFixo &&
      this.larguraDaPagina < this.larguraMenuFixo
    ) {
      this.abrirMenu();
      this.larguraDaPagina = larguraAtual;
    } else if (larguraAtual < this.larguraMenuFixo) {
      this.colapsarMenu();
      this.larguraDaPagina = larguraAtual;
    }
  }

  public mudarVisualizacaoMenu(e?: any) {
    e?.stopPropagation();
    if (
      this.sidebar.menu.nativeElement.classList.contains(
        'menu-lateral-colapsado'
      )
    ) {
      this.abrirMenu();
    } else {
      this.colapsarMenu();
    }
  }

  public lidarComClickForaDoMenu() {
    if (window.innerWidth <= 800) {
      this.colapsarMenu();
    }
  }

  public colapsarMenu() {
    if (
      !this.sidebar.menu.nativeElement.classList.contains(
        'menu-lateral-colapsado'
      )
    ) {
      this.sidebar.menu.nativeElement.classList.add('menu-lateral-colapsado');
      this.layout.nativeElement.classList.add('layout-menu-open');
    }
  }

  public abrirMenu() {
    if (
      this.sidebar.menu.nativeElement.classList.contains(
        'menu-lateral-colapsado'
      )
    ) {
      this.sidebar.menu.nativeElement.classList.remove(
        'menu-lateral-colapsado'
      );
      this.layout.nativeElement.classList.remove('layout-menu-open');
    }
  }

  public deslogar() {
    this.disabilitarBtn = true;
    this.armazenamentoDeTokenService.removerToken();
    this.armazenamentoDeUsuarioService.removerUsuario();

    this.router.navigateByUrl(`#/auth/login`);
  }

  public mostrarModal(event: any) {
    this.imageChangedEvent = event;
    this.openModal(this.editorDeImagem);
    this.arquivoParametros = { arquivo: event.target.files[0], inputConfig: { nome: 'foto_header', multiplo: false } };
  }

  public alterarFoto(arquivo: any) {
    const user = this.armazenamentoDeUsuarioService.buscarUsuario()
    let reader = new FileReader();

    reader.onload = (e: any) => {
      this.subs.add(
        this.geralService
          .patch({ endpoint: this.endpointPrincipal, id: user.id, obj: {
            cpf: user.cpf,
            email: user.email,
            foto: e.target.result,
            nome: user.nome,
            telefone: user.telefone
          }})
          .subscribe({
            next: (response: any) => {
              this.limparInput('foto_header');
              if (response.statusCode === HttpStatusCode.Ok) {
                this.usuario = response.data;
                this.armazenamentoDeUsuarioService.armazenarUsuario(response.data);
              }
            },
            error: () => {
              this.limparInput('foto_header');
            }
          })
      );
    };
    reader.readAsDataURL(arquivo);
  }

  public limparInput(nomeDoCampo: string) {
    (document.getElementById(nomeDoCampo) as HTMLInputElement).value = '';
  }

  public openModal(divElement: TemplateRef<any>, size?: 'lg' | 'sm' | 'none') {
    this.modalRefEditorImg = this.modalService.show(divElement, {
      class: `modal-${size || 'lg'}`,
      backdrop: 'static',
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
