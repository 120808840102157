<div class="page-overflow">
  <div class="page-wrapper">
    <div class="login-left">
      <div class="login-left-textos">
        <img
          src="assets/imagens/logos/destaques/logo_destaque_1.png"
          draggable="false"
          alt=""
        />
        <h5>Gestão de clientes e portais!</h5>
        <span
          >Conecte-se para ter controle total sobre o atendimento aos seus
          clientes e portais.</span
        >
      </div>
    </div>
    <div class="login-right">
      <div class="login-right-conteudo">
        <main class="login-area">
          <router-outlet></router-outlet>
        </main>
        <footer>
          <small>© Copyright 2024 G2. Todos os Direitos Reservados</small>
        </footer>
      </div>
    </div>
  </div>
</div>
