import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ArmazenamentoDeTokenService } from 'src/app/shared/services/armazenamento/armazenamento-de-token.service';
import { ToastService } from 'src/app/shared/services/utils/toast.service';
import { ArmazenamentoDeUsuarioService } from '../services/armazenamento/armazenamento-de-usuario.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class APIErroInterceptor implements HttpInterceptor {
  public clienteSlug!: string;

  constructor(
    private armazenamentoDeTokenService: ArmazenamentoDeTokenService,
    private armazenamentoDeUsuarioService: ArmazenamentoDeUsuarioService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe((params) => {
      this.clienteSlug = params.get('cliente') ?? '0';
    });
  }

  intercept(
    requisicao: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(requisicao).pipe(
      tap((response: any) => {
        const mensagem = response?.body?.data?.message;
        mensagem && this.mostrarMensagem(mensagem, 'sucesso');
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.mostrarMensagem(err?.error?.error?.message ?? 'Sessão expirada');
          this.armazenamentoDeTokenService.removerToken();
          this.armazenamentoDeUsuarioService.removerUsuario();
          this.router.navigate([`auth/login`], {
            relativeTo: this.route,
          });
        } else {
          this.mostrarMensagem(err?.error?.message);
        }
        return throwError(() => err);
      })
    );
  }

  private mostrarMensagem(mensagem: any, tipo: 'erro' | 'sucesso' = 'erro') {
    this.toastService[tipo](
      `${
        mensagem && typeof mensagem == 'string'
          ? mensagem
          : tipo === 'erro'
          ? 'Algo não saiu como esperado. Tente novamente mais tarde.'
          : ''
      }`,
      '',
      {
        timeOut: 4000,
      }
    );
  }
}
