import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { __values } from 'tslib';
import { ArmazenamentoDeBaseService, ArmazenamentoDeTokenService } from 'src/app/shared/services/armazenamento';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private tokenStorage: ArmazenamentoDeTokenService,
    private baseStorage: ArmazenamentoDeBaseService
  ) {}

  public intercept(
    requisicao: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if ((requisicao.url.indexOf('https://viacep.com.br/ws') !== -1) || (requisicao.url.indexOf('https://www.receitaws.com.br/v1') !== -1)) {
      const headers = new HttpHeaders({});
      const cloneReq = requisicao.clone({ headers });

      return next.handle(cloneReq);
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.tokenStorage.buscarToken()}`,
      Accept: 'application/json, text/plain, */*'
    });

    const cloneReq = requisicao.clone({ headers });

    return next.handle(cloneReq);
  }
}
