import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/shared/services/utils/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const mostrarAnimacaoDeCarregamentoHeader = req.headers.get('X-Show-Loading-Animation');
    const mostrarAnimacaoDeCarregamento = mostrarAnimacaoDeCarregamentoHeader === 'true';

    if (mostrarAnimacaoDeCarregamento) {
      this.loaderService.adicionarRequisicoes(req);
    }

    return new Observable(observer => {
      const subscription = next.handle(req).subscribe({
        next: event => {
          if (event instanceof HttpResponse && mostrarAnimacaoDeCarregamento) {
            this.loaderService.removerRequisicoes(req);
          }
          observer.next(event);
        },
        error: err => {
          if (mostrarAnimacaoDeCarregamento) {
            this.loaderService.removerRequisicoes(req);
          }
          observer.error(err);
        },
        complete: () => {
          if (mostrarAnimacaoDeCarregamento) {
            this.loaderService.removerRequisicoes(req);
          }
          observer.complete();
        }
      });

      return () => {
        this.loaderService.removerRequisicoes(req);
        subscription.unsubscribe();
      };
    });
  }
}
