import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MenuConfig } from 'src/app/shared/interfaces/menu-config.interface';
import { formatarTexto } from 'src/app/shared/utils/formatador-de-texto-tipo-um';
import { buscarMenuDoModulo } from './utils/buscar-menu-do-modulo';
import { ArmazenamentoDeUsuarioService } from 'src/app/shared/services/armazenamento';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule],
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @ViewChild('menu') menu: ElementRef = {} as ElementRef;
  @Output() mudarVisualizacaoMenu = new EventEmitter();

  public formatarTexto = formatarTexto;

  public menuConfig: MenuConfig[] = [];

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private armazenamentoDeUsuarioService: ArmazenamentoDeUsuarioService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.menu.nativeElement.contains(e.target)) {
        const isColapsado = this.menu.nativeElement.classList.contains(
          'menu-lateral-colapsado'
        );
        isColapsado &&
          window.innerWidth > 800 &&
          this.lidarComItemClick(1000, 1000);
      }
    });
  }

  
  ngOnInit(): void {
    const moduloId = 1;
    this.menuConfig = buscarMenuDoModulo(moduloId);
  }

  public lidarComItemClick(
    partIndex: number,
    itemIndex: number,
    isAberto?: boolean,
    e?: any
  ) {
    e?.stopPropagation();
    this.menuConfig.forEach((particao: any, particaoIndex: number) => {
      if (particao.items) {
        particao.items.forEach((item: any, partItemIndex: number) => {
          const isItemClicado =
            particaoIndex == partIndex && partItemIndex == itemIndex;
          const isAbertoNovoValor = isAberto
            ? isAberto
            : !this.menuConfig[partIndex]?.items[itemIndex].isAberto;
          this.menuConfig[particaoIndex].items[partItemIndex].isAberto =
            isItemClicado ? isAbertoNovoValor : false;
        });
      }
    });
  }

  public navegarParaEndpoint(endpoint: number | string) {
    this.router.navigate([`${endpoint}`], { relativeTo: this.route });
  }
}
