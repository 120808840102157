const menuGerenciamento: any[] = [
  {
    particao: { nome: 'FUNÇÕES' },
    items: [
      { icone: 'bx bx-user', nome: 'Usuários' },
      { icone: 'bx bx-group', nome: 'Clientes' },
      { icone: 'bx bx-sitemap', nome: 'Portais' },
      { icone: 'bx bx-clipboard', nome: 'planos', 
        subItems: [
          { nome: 'Acomodações' },
          { nome: 'Abrangências' },
          { nome: 'Contratações' },
          { nome: 'Segmentações' },
          { nome: 'Categorias' },
          { nome: 'Tipos De Portais', path: 'tipos-de-portais' }
        ]
      }
    ],
  },
];

export function buscarMenuDoModulo(id: any) {
  let menu: any[] = [];

  switch (id) {
    case 1:
      menu = menuGerenciamento;
      break;
  }

  return menu;
}
