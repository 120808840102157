import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ArmazenamentoDeTokenService } from 'src/app/shared/services/armazenamento/armazenamento-de-token.service';

@Injectable({
  providedIn: 'root',
})
export class AutorizacaoGuard {
  constructor(
    private armazenamentoDeTokenService: ArmazenamentoDeTokenService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      const token = this.armazenamentoDeTokenService.buscarToken();
      const clienteSlug = route.paramMap ?? null;

      if (!token) {
        const url = clienteSlug ? 
        `${window.origin}/web/#/auth/${route.paramMap.get('cliente')}/login` : 
        `${window.origin}/web/#/auth/buscar-cliente`
        window.location.href = url;
      }
      resolve(token ? true : false);
    });
  }
}
